<template>
  <ValidationObserver @submit="addEditStudent">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="showModal"
      :modal-width="50"
      :disable-position-center="true"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span :key="headerTitle" v-i18n="dashboard">
          {{ headerTitle }}
        </span>
      </template>
      <div class="checkInModal-body">
        <div v-if="currentStat === 1">
          <InputFieldWrapper>
            <UiInput
              v-model.trim="studentData.student_info.first_name"
              type="text"
              name="Student First Name"
              title="First Name"
              label="First Name"
              placeholder="Enter First Name"
              rules="required|alpha_spaces"
              class="flex-1"
            />
            <UiInput
              v-model.trim="studentData.student_info.last_name"
              type="text"
              name="Student Last Name"
              title="Last Name"
              label="Last Name"
              placeholder="Enter Last Name"
              rules="required|alpha_spaces"
              class="flex-1"
            />
          </InputFieldWrapper>

          <InputFieldWrapper>
            <UiSingleSelect
              v-model="studentData.student_info.gender"
              title="Gender"
              class="flex-1"
              :options="genderArray"
            />
            <UiInput
              id="img-btn"
              ref="imgUpload"
              v-model="studentImage"
              title="Photo"
              placeholder="Photo Upload"
              :preview-image="previewImage"
              type="file"
              class="flex-1"
              :rules="imageRules"
              @preview="preview"
              @clearValue="clearValue"
            />
          </InputFieldWrapper>

          <InputFieldWrapper>
            <UiInput
              v-model="studentData.student_info.email"
              type="email"
              name="Student Email"
              title="Email"
              label="Email"
              class="flex-1"
              placeholder="Email"
              :rules="isStudentActivated && !studentPhoneNumber ? 'required|email' : 'email'"
            />
            <PhoneNumber
              v-model="studentPhoneNumber"
              title="PHONE_NUMBER"
              name="Phone Number"
              :rules="
                isStudentActivated && !studentData.student_info.email && !edit
                  ? 'required|validNumber'
                  : 'validNumber'
              "
              class="flex-1"
              @update="updateStudentNumber"
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <UiDatePicker
              v-model="studentData.profile_info.date_of_birth"
              class="flex-1"
              title="DOB"
            />
            <UiInput
              v-model.trim="studentData.profile_info.religion"
              type="text"
              name="Student Religion"
              title="Religion"
              label="Religion"
              class="flex-1"
              placeholder="Religion"
              rules="alpha_spaces"
            />
          </InputFieldWrapper>

          <InputFieldWrapper>
            <UiSingleSelect
              v-model="studentData.profile_info.blood_group"
              title="Blood Group"
              class="flex-1"
              :options="bloodGroupArray"
            />
            <UiInput
              v-model="studentData.profile_info.address"
              type="text"
              name="Student Address"
              title="Address"
              label="Address"
              placeholder="Address"
              class="flex-1"
            />
          </InputFieldWrapper>

          <InputFieldWrapper>
            <UiInput
              v-if="currentSectionScope"
              v-model.trim="studentData.roll_no"
              type="text"
              name="Roll Number"
              title="Roll Number"
              label="Roll Number"
              placeholder="Roll Number"
              rules="alpha_numeric_dashes"
              class="flex-1"
            />
            <UiInput
              v-model.trim="studentData.student_info.registration_no"
              type="text"
              name="Registration Number"
              title="Registration Number"
              label="Registration Number"
              placeholder="Registration Number"
              rules="alpha_numeric_dashes"
              class="flex-1"
            />
            <UiSingleSelect
              v-if="!currentSectionScope"
              v-model="studentData.identity_info.type"
              title="Identity Type"
              :options="identityType"
              label="title"
              class="flex-1"
              reduce="value"
              :class="studentData.identity_info.type && 'sm:w-1/2'"
              @change="resetIdentityInfo()"
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <div class="flex sm:flex-row flex-col sm:gap-6 w-full">
              <UiSingleSelect
                v-if="currentSectionScope"
                v-model="studentData.identity_info.type"
                title="Identity Type"
                :options="identityType"
                label="title"
                reduce="value"
                :class="studentData.identity_info.type && 'sm:w-1/2'"
                @change="resetIdentityInfo()"
              />
              <UiInput
                v-if="studentData.identity_info.type"
                v-model="studentData.identity_info.value"
                type="text"
                name="Identity Number"
                title="Identity Number"
                label="Identity Number"
                placeholder="Enter Identity No"
                class="flex-1"
                rules="required|numeric"
              />
              <UiInput
                v-if="!currentSectionScope && studentData.identity_info.type"
                v-model="studentData.identity_info.country"
                type="text"
                name="Country"
                title="Country"
                label="Country"
                placeholder="Enter Country"
                class="flex-1"
                rules="required|alpha"
              />
            </div>
          </InputFieldWrapper>
          <InputFieldWrapper>
            <UiInput
              v-if="currentSectionScope && studentData.identity_info.type"
              v-model="studentData.identity_info.country"
              type="text"
              name="Country"
              title="Country"
              label="Country"
              placeholder="Enter Country"
              class="flex-1"
              rules="required|alpha"
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <div v-if="!edit && currentClassScope && !currentSectionScope" class="flex-1">
              <UiSingleSelect
                v-model="studentData.section"
                title="Section"
                :options="usersSectionList"
                label="title"
                :select-object-without-reduce-key="true"
                :rules="requiredWhenClassLevel"
                class="flex-1"
                :search-through-api="true"
                @search="getSection"
              />
            </div>
            <UiSingleSelect
              v-if="!edit && !currentCampusScope"
              v-model="studentData.academic_info.campus_id"
              title="CAMPUSES"
              :search-through-api="true"
              label="title"
              :options="campuses"
              class="flex-1"
              reduce="id"
              rules="required"
              @search="getCampus"
            />
            <ToggleButton
              v-if="!edit"
              v-model="isStudentActivated"
              heading="Enable Login"
              class="flex-1"
              :is-status-hide="true"
            />
          </InputFieldWrapper>
        </div>
        <div v-if="currentStat === 2">
          <div v-if="!edit">
            <UserDetailedView :user="userInfo" />
            <div
              class="pl-5 font-normal text-lg text-text-color w-full pb-2 flex-1 border-t border-primary-purple-100 pt-4"
            >
              Do You Want To:
            </div>
            <div class="mb-5 px-3 flex mx-2 flex-wrap sm:pl-3 flex-1 justify-center pb-2">
              <span class="mt-1 font-normal text-xs lg:text-base leading-7 text-text-color flex-2">
                Associate Existing Guardian
              </span>
              <div class="px-4">
                <ToggleButton
                  v-model="associateNewGuardian"
                  class="mt-1 lg:mt-2 flex-1"
                  checkbox-color-class="bg-dark-orange"
                  :is-status-hide="true"
                  :custom-style="{ backgroundColor: 'var(--primary-purple-600)' }"
                />
              </div>
              <span class="mt-1 font-normal text-xs lg:text-base leading-7 text-text-color flex-2">
                Create & Associate New Guardian
              </span>
            </div>
            <div v-if="associateNewGuardian">
              <InputFieldWrapper>
                <UiInput
                  v-model="studentData.guardian_info.first_name"
                  type="text"
                  name="First Name"
                  title="First Name"
                  label="First Name"
                  placeholder="Enter First Name"
                  rules="required|alpha_spaces"
                  class="flex-1"
                />
                <UiInput
                  v-model="studentData.guardian_info.last_name"
                  type="text"
                  name="Last Name"
                  title="Last Name"
                  label="Last Name"
                  placeholder="Enter Last Name"
                  rules="required|alpha_spaces"
                  class="flex-1"
                />
              </InputFieldWrapper>
              <InputFieldWrapper>
                <UiSingleSelect
                  v-model="studentData.guardian_info.gender"
                  title="Gender"
                  class="flex-1"
                  :options="genderArray"
                />
                <UiInput
                  id="img-btn"
                  ref="imgUpload"
                  v-model="studentImage"
                  title="Photo"
                  class="flex-1"
                  placeholder="Photo Upload"
                  type="file"
                  :preview-image="previewImage"
                  :rules="imageRules"
                  @preview="preview"
                  @clearValue="clearValue"
                />
              </InputFieldWrapper>
              <InputFieldWrapper>
                <UiInput
                  v-model="studentData.guardian_info.email"
                  type="email"
                  name="Email"
                  title="Email"
                  label="Email"
                  placeholder="Email"
                  class="flex-1"
                  :rules="isGuardianActivated && !guardianPhoneNumber ? 'required|email' : 'email'"
                />
                <PhoneNumber
                  v-model="guardianPhoneNumber"
                  title="Phone Number"
                  rules="validNumber"
                  class="flex-1"
                  @update="updateGuardianNumber"
                />
              </InputFieldWrapper>
              <InputFieldWrapper>
                <UiDatePicker
                  v-model="studentData.guardian_info.date_of_birth"
                  title="DOB"
                  class="flex-1"
                />
                <UiInput
                  v-model.trim="studentData.guardian_info.religion"
                  type="text"
                  name="Religion"
                  title="Religion"
                  label="Religion"
                  placeholder="Religion"
                  class="flex-1"
                  rules="alpha_spaces"
                />
              </InputFieldWrapper>
              <InputFieldWrapper>
                <UiSingleSelect
                  v-model="studentData.guardian_info.blood_group"
                  title="Blood Group"
                  class="flex-1"
                  :options="bloodGroupArray"
                />
                <UiInput
                  v-model="studentData.guardian_info.address"
                  type="text"
                  name="Address"
                  title="Address"
                  label="Address"
                  placeholder="Address"
                  class="flex-1"
                />
              </InputFieldWrapper>
              <InputFieldWrapper>
                <UiSingleSelect
                  v-model="studentData.guardian_info.type"
                  title="Identity Type"
                  :options="identityType"
                  label="title"
                  reduce="value"
                  class="flex-1"
                  @change="resetData"
                />
                <UiInput
                  v-model="studentData.guardian_info.relation"
                  type="text"
                  name="relation"
                  title="relation"
                  label="relation"
                  placeholder="relation"
                  rules="required|alpha_spaces"
                  class="flex-1"
                />
              </InputFieldWrapper>
              <InputFieldWrapper>
                <div
                  v-if="studentData.guardian_info.type"
                  class="flex sm:flex-row flex-col sm:gap-6 w-full"
                >
                  <UiInput
                    v-model="studentData.guardian_info.value"
                    type="text"
                    name="Identity Number"
                    title="Identity Number"
                    label="Identity Number"
                    placeholder="Enter Identity No"
                    class="flex-1"
                    rules="required|numeric"
                  />
                  <UiInput
                    v-model="studentData.guardian_info.country"
                    type="text"
                    name="Country"
                    title="Country"
                    label="Country"
                    placeholder="Enter Country"
                    class="flex-1"
                    rules="required|alpha"
                  />
                </div>
              </InputFieldWrapper>
              <InputFieldWrapper class="justify-end">
                <div class="w-full sm:w-[48%]">
                  <ToggleButton
                    v-model="studentData.guardian_info.is_main_guardian"
                    heading="Main Guardian"
                    :is-status-hide="true"
                    class="flex-1"
                  />
                  <ToggleButton
                    v-if="!edit"
                    v-model="studentData.is_guardian_pickup_person"
                    class="mt-6 flex-1"
                    heading="Student Pickup Person"
                    :is-status-hide="true"
                  />
                </div>
              </InputFieldWrapper>
            </div>
            <div v-else>
              <InputFieldWrapper>
                <UiSingleSelect
                  v-model="studentData.guardian_info.guardian_id"
                  title="GUARDIAN"
                  label="full_name"
                  class="flex-1"
                  :options="guardiansList"
                  :image="true"
                  reduce="guardian_id"
                  rules="required"
                  :search-through-api="true"
                  @search="getAllGuardiansList"
                />
                <UiInput
                  v-model="studentData.guardian_info.relation"
                  type="text"
                  name="relation"
                  title="relation"
                  label="relation"
                  placeholder="relation"
                  class="flex-1"
                  rules="required|alpha"
                />
              </InputFieldWrapper>
            </div>
            <div v-if="!edit && !associateNewGuardian">
              <InputFieldWrapper>
                <ToggleButton
                  v-model="studentData.is_guardian_pickup_person"
                  class="mt-1 flex-1"
                  heading="Student Pickup Person"
                  :is-status-hide="true"
                />
                <ToggleButton
                  v-model="studentData.guardian_info.is_main_guardian"
                  heading="Main Guardian"
                  :is-status-hide="true"
                  class="flex-1 sm:mt-0 mt-6"
                />
              </InputFieldWrapper>
            </div>
          </div>
        </div>
        <div v-if="currentStat === 3">
          <UserDetailedView :user="userInfo" />
          <div class="pt-6 border-t primary-purple-100">
            <InputFieldWrapper>
              <UiSingleSelect
                v-model="studentData.academic_info.class"
                title="CLASS"
                :options="usersClassList"
                label="title"
                :select-object-without-reduce-key="true"
                :search-through-api="true"
                :rules="!!currentCampusScope ? 'required' : ''"
                :disabled="!!currentClassScope"
                class="flex-1"
                @change="setSection(studentData.academic_info.class?.id)"
                @deSelect="setSection(studentData.academic_info.class?.id)"
                @search="getClasses"
              />
              <UiSingleSelect
                v-model="studentData.academic_info.section"
                title="SECTION"
                :options="usersSectionList"
                label="title"
                :select-object-without-reduce-key="true"
                :search-through-api="true"
                :rules="requiredWhenClassLevel"
                class="flex-1"
                :disabled="currentSectionScope || !studentData.academic_info.class?.id"
                @search="getSection"
              />
            </InputFieldWrapper>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div v-if="currentStat > 2 && showPrevious" class="flex flex-1">
          <UIButton button="primary-previous" :icon="true" @click="prevStep">Previous</UIButton>
        </div>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :key="currentStat" :disabled="isLoading" @click="dropAction()">
            {{ closeTitle }}
          </UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <div v-if="isLoading">
              <Loader></Loader>
            </div>
            <div v-else class="px-2">
              {{ title }}
            </div>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
/* COMPONENTS */
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import PhoneNumber from '@components/UiElements/PhoneNumber.vue'
import ToggleButton from '@src/components/BaseComponent/toggleButton.vue'
import UserDetailedView from '@src/components/userDetailedView.vue'
import RULE_CONSTANTS from '@src/constants/rule.constants.js'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'

/* MIXINS */
import generalMixin from '@src/mixins/general-mixins.js'
/* STATE */
import { mapActions, mapState } from 'vuex'
import { transformStudentData } from '@src/utils/settings/tenant-user.util.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import { currentUTCDateTimeWithZone } from '@src/utils/moment.util.js'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

/* EXPORT AND RENDER */
export default {
  components: {
    UserDetailedView,
    UiModalContainer,
    UiInput,
    UiDatePicker,
    UiSingleSelect,
    Loader,
    ValidationObserver,
    PhoneNumber,
    ToggleButton,
    UIButton,
    InputFieldWrapper,
  },
  mixins: [generalMixin],
  props: {
    modal: { type: Boolean },
    edit: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      studentPhoneNumber: '',
      guardianPhoneNumber: '',
      options: ['Select'],
      previewImage: null,
      showPrevious: false,
      currentStat: 1,
      studentImage: null,
      password: '',
      identityType: [
        { title: this.$t(`dashboard.TM_P2_DD2_O1_L1`), value: 'cnic' },
        { title: this.$t(`dashboard.TM_P2_DD2_O2_L2`), value: 'driving_licence' },
        { title: this.$t(`dashboard.TM_P2_DD2_O3_L3`), value: 'passport' },
      ],
      dashboard: 'dashboard',
      genderArray: [this.$t(`dashboard.male`), this.$t(`dashboard.female`)],
      bloodGroupArray: ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'],
      steps: [
        { stat: 1, info: 'Personal Info', active: true, markStatus: false },
        { stat: 2, info: 'GUARDIAN_INFO', active: false, markStatus: false },
        { stat: 3, info: 'Academic Info', active: false, markStatus: false },
      ],
      user_id: '',
      student_id: '',
      studentData: {
        section: null,
        student_info: {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          gender: '',
          user_type: 'student',
          registration_no: '',
          login_activated_at: '',
        },
        profile_info: {
          blood_group: '',
          date_of_birth: '',
          religion: '',
          nationality: '',
          address: '',
        },
        roll_no: '',
        guardian_info: {
          phone: '',
          email: '',
        },
        is_guardian_pickup_person: false,
        identity_info: {
          type: '',
          value: '',
          country: '',
        },
        academic_info: {
          section: null,
          campus_id: '',
          year: '',
          class: null,
        },
      },
      userInfo: {
        profile: {},
      },
      isGuardianActivated: true,
      isStudentActivated: true,
      guardiansList: null,
      associateNewGuardian: true,
    }
  },
  computed: {
    ...mapState('layout', [
      'campuses',
      'isLoading',
      'currentCampusScope',
      'currentSectionScope',
      'currentClassScope',
    ]),
    ...mapState('instituteCount', ['instituteCount']),
    ...mapState({ usersClassList: (state) => state.classes.usersClassList }),
    ...mapState({ usersSectionList: (state) => state.section.usersSectionList }),
    showModal() {
      let result
      if (this.modal && this.currentStat !== 3) result = true
      else if (this.modal && this.currentStat === 3 && !this.currentClassScope) {
        result = true
      } else result = false
      return result
    },
    title() {
      if (this.edit) {
        return 'Update'
      } else if (this.currentStat === 1) {
        return 'Add'
      } else if (this.currentStat === 2 && this.associateNewGuardian) {
        return 'Create & Associate Guardian'
      } else if (this.currentStat === 2) {
        return 'Associate Guardian'
      } else {
        return 'Enroll'
      }
    },
    headerTitle() {
      if (this.edit) {
        return 'Edit Student'
      } else if (this.currentStat === 1) {
        return 'Add Student'
      } else if (this.currentStat === 2) {
        return 'Create And Associate Guardian'
      } else {
        return 'ENROLL_STD'
      }
    },
    closeTitle() {
      if (this.currentStat === 2 && !this.currentSectionScope) return 'Skip'
      else if (this.currentStat === 3) return 'Close'
      else return 'Cancel'
    },
    requiredWhenClassLevel() {
      return this.currentCampusScope || this.studentData.academic_info.class?.id ? 'required' : ''
    },
    imageRules() {
      return `${RULE_CONSTANTS.SIZE}`
    },
  },
  watch: {
    'studentData.academic_info.class': {
      handler() {
        if (this.studentData.academic_info) this.studentData.academic_info.section = null
      },
    },
  },
  /**
   * In Created hook we set the current selected student details
   * Set selected Campus
   * Set selected section
   */

  created() {
    if (this.edit) this.setStudentData()
    this.initData()
  },
  methods: {
    updateGuardianNumber(payload) {
      this.studentData.guardian_info.phone = payload.formattedNumber
    },
    resetData() {
      this.studentData.guardian_info.value = ''
      this.studentData.guardian_info.country = ''
    },

    setData() {
      let currentClass = this.teacher?.academic_info?.class
      let currentSection = this.teacher?.academic_info?.section
      if (currentClass?.id) {
        this.$store.commit(
          'classes/SET_USERS_CLASS_LIST',
          addItemInArray(currentClass, this.usersClassList),
        )
      }
      if (currentSection?.id) {
        this.$store.commit(
          'section/SET_USER_SECTIONS_LIST',
          addItemInArray(currentSection, this.usersSectionList),
        )
      }
    },
    initData() {
      if (this.currentCampusScope) {
        this.getClasses()
      }
    },
    /**
     * Get Campus Section from server
     * Set response in state
     */

    getClasses(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
          ...buildWhereQuery(
            FILTER_KEYS.EQUAL,
            'campus_id',
            this.studentData.academic_info?.campus_id || this.currentCampusScope?.id,
          ),
        },
      }
      this.getUserClasses(payload).then(() => {
        if (this.currentClassScope) {
          this.studentData.academic_info.class = this.currentClassScope
          this.setSection(this.currentClassScope?.id)
        }
        if (this.currentSectionScope && this.studentData.academic_info) {
          this.studentData.academic_info.section = this.currentSectionScope
          this.getAllGuardiansList()
        }
      })
    },
    resetIdentityInfo() {
      this.studentData.identity_info.value = ''
      this.studentData.identity_info.country = ''
    },
    /**
     * Set previous step inactive and mark its status true
     * Set next step active and mark its status false
     */
    nextStep() {
      if (this.currentStat < this.steps.length) {
        this.steps[this.currentStat - 1].active = false
        this.steps[this.currentStat - 1].markStatus = true
        this.currentStat += 1
        this.steps[this.currentStat - 1].markStatus = false
        this.steps[this.currentStat - 1].active = true
      }
    },
    /**
     * Set selecte image to preview
     * @param {Object} image- selected image
     */
    preview(image) {
      this.previewImage = image
    },
    clearValue(image) {
      this.previewImage = null
      this.studentImage = image
    },

    getSection(query = '') {
      this.setSection(this.studentData.academic_info.class?.id, query)
    },
    setSection(id, query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'class_id', id || this.currentClassScope?.id),
        },
      }
      this.filterUsersSectionsList(payload)
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          if (this.currentStat > 1) this.$emit('toggle', true)
          else {
            this.$emit('toggle')
          }
          this.previewImage = null
          break
        case 'cancel':
          break
        case 'confirm':
          break
      }
    },
    /**
     * Submit form with student data
     * If edit existing student call editExistingStudent
     * If add new student call addNewStudent function
     * @param {Boolean} invalid - form data validation
     */
    dropAction() {
      if (this.currentStat === 2 && !this.currentSectionScope) {
        this.nextStep()
        this.showPrevious = true
        this.handleClick('close')
      } else this.handleClick('close')
      this.previewImage = null
    },
    addEditStudent() {
      /* Student phone number logic */
      if (!this.edit) {
        if (this.currentStat === 1) this.addStudent()
        else if (this.currentStat === 2 && this.associateNewGuardian) this.addGuardian()
        else if (this.currentStat === 2) this.associateGuardian()
        else if (this.currentStat === 3 && !this.currentSectionScope) this.enrollStudent()
      } else this.editExistingStudent()
    },
    /**
     * Go back to previous step
     * Mark all forward step inactive and mark their status false
     */

    prevStep() {
      if (this.currentStat > 1) {
        this.currentStat -= 1
        this.showPrevious = false
      }
      if (this.currentStat > 0) {
        for (let i = 0; i <= this.currentStat; i++) {
          this.steps[i].active = true
        }
        this.steps[this.currentStat].active = false
        this.steps[this.currentStat].markStatus = false
        this.steps[this.currentStat - 1].markStatus = false
      }
    },

    /**
     * Edit existing student
     * Append student data and its profile picture in form data
     * Send put request with form data
     */

    editExistingStudent() {
      this.$store.commit('layout/IS_LOADING', true)
      this.studentData.student_info.login_activated_at = this.isStudentActivated
        ? currentUTCDateTimeWithZone()
        : null

      const form = new FormData()
      if (this.studentImage) form.append('profile_image', this.studentImage)
      this.studentData.deassociate_guardians = []
      form.append('data', JSON.stringify(this.studentData))
      const data = {
        student: form,
        id: this.edit?.id,
      }
      this.editStudent(data)
        .then((res) => {
          let studentMode = !!localStorage.getItem('currentActiveStudent')
          if (studentMode) localStorage.setItem('currentActiveStudent', JSON.stringify(res.data))
          this.$store.commit('student/SET_LISTEN_STORAGE', true)
        })
        .finally(() => {
          this.$store.commit('layout/IS_LOADING', false)
          this.previewImage = null
          this.$emit('toggle', true)
        })
    },

    /**
     * Add new student
     * Append student data and its profile picture in form data
     * Send post request with form data
     */
    addStudent() {
      this.$store.commit('layout/IS_LOADING', true)
      const data = new FormData()
      if (this.isStudentActivated) data.append('login_activated_at', currentUTCDateTimeWithZone())
      else data.append('auto_generate_password', true)
      data.append('first_name', this.studentData?.student_info?.first_name || '')
      data.append('last_name', this.studentData?.student_info?.last_name || '')
      data.append('email', this.studentData?.student_info?.email || '')
      data.append('phone', this.studentData?.student_info?.phone || '')
      data.append('gender', this.studentData?.student_info?.gender || '')
      data.append('roll_no', this.studentData?.roll_no || '')
      data.append('registration_no', this.studentData?.student_info?.registration_no || '')
      data.append('blood_group', this.studentData?.profile_info?.blood_group || '')
      data.append('date_of_birth', this.studentData?.profile_info?.date_of_birth || '')
      data.append('religion', this.studentData?.profile_info?.religion || '')
      data.append('nationality', this.studentData?.profile_info?.nationality || '')
      data.append('address', this.studentData?.profile_info?.address || '')
      data.append('type', this.studentData?.identity_info?.type || '')
      data.append('value', this.studentData?.identity_info?.value || '')
      data.append('country', this.studentData?.identity_info?.country || '')
      if (this.studentImage) {
        data.append('profile_image', this.studentImage || '')
      }
      if (this.studentData.section) data.append('section_id', this.studentData?.section?.id)
      if (this.studentData.academic_info?.campus_id)
        data.append('campus_id', this.studentData?.academic_info?.campus_id || '')
      this.addNewStudent(data)
        .then((res) => {
          this.$store.commit('instituteCount/INCREMENT_INSTITUTE_COUNT', 'students_count')
          this.getInstituteCount()
          this.student_id = res.student_info.id
          this.user_id = res.id
          this.getUserInfo()
          this.nextStep()
        })
        .finally(() => {
          this.$store.commit('layout/IS_LOADING', false)
          this.previewImage = null
        })
    },
    getUserInfo() {
      this.userInfo.first_name = this.studentData.student_info.first_name
      this.userInfo.last_name = this.studentData.student_info.last_name
      this.userInfo.full_name = `${this.studentData.student_info.first_name} ${this.studentData.student_info.last_name}`
      this.userInfo.gender = this.studentData.student_info.gender
      this.userInfo.profile.date_of_birth = this.studentData.profile_info.date_of_birth
      this.userInfo.phone = this.studentData.student_info.phone
      this.userInfo.email = this.studentData.student_info.email
    },
    associateGuardian() {
      let payload = {
        guardian_info_id: this.studentData.guardian_info.guardian_id,
        student_info_id: this.student_id,
        relation: this.studentData.guardian_info.relation,
        is_main_guardian: this.studentData.guardian_info.is_main_guardian,
        is_guardian_pickup_person: this.studentData.is_guardian_pickup_person,
      }
      this.associateStudentWithGuardian(payload)
        .then(() => {
          if (this.currentSectionScope) this.handleClick('close')
          else this.nextStep()
        })
        .finally(() => {
          this.$store.commit('layout/IS_LOADING', false)
        })
    },
    getCampus(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getCampusList(payload)
    },
    enrollStudent() {
      let payload = {
        campus_id: this.studentData?.academic_info?.campus_id || this.currentCampusScope?.id,
        section_id: this.studentData.academic_info.section?.id,
        class_id: this.studentData.academic_info.class?.id,
        student_id: this.user_id,
      }
      this.enrollNewStudent(payload)
        .then(() => {
          this.handleClick('close')
        })
        .finally(() => {
          this.$store.commit('layout/IS_LOADING', false)
        })
    },
    /**
     * Add new Guardian and Associated that guardian
     * Append student data and its profile picture in form data
     * Send post request with form data
     */
    addGuardian() {
      this.$store.commit('layout/IS_LOADING', true)
      const data = new FormData()
      if (
        this.studentData &&
        this.studentData.guardian_info &&
        this.studentData.guardian_info.password
      )
        data.append('password', this.studentData.guardian_info.password)
      else data.append('auto_generate_password', true)
      data.append('first_name', this.studentData?.guardian_info?.first_name || '')
      data.append('last_name', this.studentData?.guardian_info?.last_name || '')
      data.append('gender', this.studentData?.guardian_info?.gender || '')
      data.append('email', this.studentData?.guardian_info?.email || '')
      data.append('phone', this.guardianPhoneNumber || '')
      data.append('roll_no', this.studentData?.guardian_info?.roll_no || '')
      data.append('registration_no', this.studentData?.guardian_info?.registration_no || '')
      data.append('blood_group', this.studentData?.guardian_info?.blood_group || '')
      data.append('date_of_birth', this.studentData?.guardian_info?.date_of_birth || '')
      data.append('religion', this.studentData?.guardian_info?.religion || '')
      data.append('nationality', this.studentData?.guardian_info?.nationality || '')
      data.append('type', this.studentData?.guardian_info?.type || '')
      data.append('value', this.studentData?.guardian_info?.value || '')
      data.append('country', this.studentData?.guardian_info?.country || '')
      data.append('address', this.studentData?.guardian_info?.address || '')
      data.append('relation', this.studentData?.guardian_info?.relation || '')
      data.append('profile_image', this.studentData?.guardian_info?.image || '')
      data.append('is_main_guardian', this.studentData?.guardian_info?.is_main_guardian || 'false')
      data.append('is_guardian_pickup_person', this.studentData?.is_guardian_pickup_person || '')
      data.append('student_info_id', this.student_id || '')
      this.createGuardian(data)
        .then(() => {
          if (this.currentSectionScope) this.handleClick('close')
          else this.nextStep()
        })
        .finally(() => {
          this.$store.commit('layout/IS_LOADING', false)
          this.previewImage = null
        })
    },
    /**
     * Mobile phone handle
     */
    updateStudentNumber(payload) {
      this.studentData.student_info.phone = payload.formattedNumber
    },
    updateGardianNumber(payload) {
      this.studentData.guardian_info.phone = payload.formattedNumber
    },
    setStudentData() {
      this.studentData = transformStudentData(this.edit)
      this.isStudentActivated = !!this.edit?.login_activated_at
      this.previewImage = {}
      this.studentImage = this.edit?.image
      this.previewImage.src = this.edit?.image || require('@src/assets/images/upload.svg')
      this.studentPhoneNumber = this.edit?.phone
      this.steps.length = 1
      this.gardianPhoneNumber = this.edit?.guardian_info?.phone
    },
    getAllGuardiansList(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
          ...buildWhereQuery(
            FILTER_KEYS.EQUAL,
            'campus_id',
            this.studentData?.academic_info?.campus_id,
          ),
        },
      }
      this.getGuardiansWithFilter(payload).then((res) => {
        this.guardiansList = res?.records
        this.guardiansList.forEach(
          (guardian) => (guardian.full_name = `${guardian.first_name} ${guardian.last_name}`),
        )
      })
    },
    /**
     * STORE METHODS
     */
    ...mapActions('student', [
      'addNewStudent',
      'editStudent',
      'associateStudentWithGuardian',
      'enrollNewStudent',
    ]),
    ...mapActions('layout', ['getCampusList']),
    ...mapActions('section', ['filterUsersSectionsList']),
    ...mapActions('instituteCount', ['getInstituteCount']),
    ...mapActions('classes', ['getUserClasses']),
    ...mapActions('guardian', ['getGuardiansWithFilter', 'createGuardian']),
  },
}
</script>
